import React from "react";

export default function Feature({ icon: Icon, title, description }) {
  return (
    <div
      className="relative group p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden"
    >
      <div
        className={`absolute inset-0 bg-gradient-to-r from-brand-teal to-brand-green opacity-0 group-hover:opacity-5 transition-opacity duration-300`}
      ></div>
      {/* Icon and Title Side by Side */}
      <div className="flex items-center gap-4 mb-4">
        <div
          className={`w-12 h-12 rounded-xl bg-gradient-to-r from-brand-teal to-brand-green p-0.5`}
        >
          <div className="w-full h-full rounded-xl flex items-center justify-center">
            <Icon className="w-6 h-6 bg-gradient-to-r from-brand-teal to-brand-green bg-clip-text text-white" />
          </div>
        </div>
        <h3 className="text-2xl font-semibold text-brand-navy">{title}</h3>
      </div>
      {/* Description Below */}
      <p className="text-gray-600">{description}</p>
    </div>
  );
}
