import React from 'react';
import { FaRegHeart } from "react-icons/fa6";
import Travelers from '../../assets/Travelers.png';
import { FaEarthAmericas } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';
import { LuCoins } from "react-icons/lu";
import Button from '../Button';
import styles from '../../utility/styles';

export default function Hero({ actionBtnPress }) {
  const { t } = useTranslation();

  return (
    <div
      className="hero relative overflow-hidden bg-gradient-to-br from-brand-navy to-brand-teal text-white pt-[var(--navbar-height)]"
      style={{ position: "relative", zIndex: 1 }}
    >
      <div
        style={{ backgroundImage: `url(${Travelers})` }}
        className="absolute inset-0 opacity-20 bg-cover bg-center"
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 relative">

        <div className="text-center max-w-3xl mx-auto">
          <h1 className="text-5xl font-bold mb-6">
            {t("communities.hero_h")}
          </h1>
          <p className="text-xl mb-8 text-gray-200">
            {t("communities.hero_p")}
          </p>

          <Button
            text={t("join_waitlist")}
            className={styles.button.primary}
            onClick={actionBtnPress}
            style={{ margin: "auto" }}
          />
        </div>

        <div id="hero_pros" className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16">
          {[
            {
              icon: <FaRegHeart className="w-8 h-8" />,
              title: t("communities.hero_connection_h"),
              description: t("communities.hero_connection_p")
            },
            {
              icon: <LuCoins className="w-8 h-8" />,
              title: t("communities.hero_earn_h"),
              description: t("communities.hero_earn_p")
            },
            {
              icon: <FaEarthAmericas className="w-8 h-8" />,
              title: t("communities.hero_global_h"),
              description: t("communities.hero_global_p"),
            }
          ].map((feature, index) => (
            <div key={index} className="bg-white/10 backdrop-blur-lg rounded-xl p-6 text-center">
              <div className="inline-block p-4 bg-brand-green rounded-full text-white shadow-lg transform hover:rotate-6 transition-transform duration-300">
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold mb-2 text-gray-100">{feature.title}</h3>
              <p className="text-gray-300">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}