import React from 'react';
import { BsCreditCard } from "react-icons/bs";
import { LuUsers } from "react-icons/lu";
import { FiMessageCircle } from "react-icons/fi";
import { AiOutlineBarChart } from "react-icons/ai";

export default [
  {
    title: "Community Dashboard",
    description: "Track your community's growth and engagement in real-time",
    content: (
      <div className="bg-gray-50 h-full p-4 rounded-lg">
        <div className="flex justify-between items-center mb-6">
          <h4 className="text-lg font-semibold text-brand-navy">Community Overview</h4>
          <div className="flex space-x-2">
            <button className="px-3 py-1 bg-brand-teal text-white rounded-md text-sm">Daily</button>
            <button className="px-3 py-1 bg-white text-gray-600 rounded-md text-sm">Weekly</button>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mb-6">
          {[
            { label: "Members", value: "2,847", icon: <LuUsers className="w-4 h-4" /> },
            { label: "Posts", value: "156", icon: <FiMessageCircle className="w-4 h-4" /> },
            { label: "Revenue", value: "$1,234", icon: <BsCreditCard className="w-4 h-4" /> }
          ].map((stat, i) => (
            <div key={i} className="bg-white p-3 rounded-lg">
              <div className="flex items-center space-x-2 mb-2">
                <div className="p-1.5 bg-brand-teal/10 rounded-md text-brand-teal">
                  {stat.icon}
                </div>
                <span className="text-sm text-gray-600">{stat.label}</span>
              </div>
              <p className="text-lg font-semibold text-brand-navy">{stat.value}</p>
            </div>
          ))}
        </div>
        <div className="bg-white p-4 rounded-lg">
          <div className="h-32 flex items-end space-x-2">
            {[40, 65, 45, 80, 55, 70, 60].map((height, i) => (
              <div key={i} className="flex-1">
                <div
                  className="bg-brand-teal/80 rounded-t-md"
                  style={{ height: `${height}%` }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  },
  {
    title: "Engagement Tools",
    description: "Create and manage events, polls, and discussions",
    content: (
      <div className="bg-gray-50 h-full p-4 rounded-lg">
        <div className="space-y-4">
          <div className="bg-white p-4 rounded-lg">
            <div className="flex items-center justify-between mb-3">
              <h4 className="font-medium text-brand-navy">Weekend Hiking Event</h4>
              <span className="px-2 py-1 bg-brand-green/10 text-brand-green rounded-md text-sm">Active</span>
            </div>
            <div className="flex space-x-4">
              <div className="w-16 h-16 bg-brand-teal/10 rounded-lg flex items-center justify-center">
                <span className="text-brand-teal font-semibold">23 MAR</span>
              </div>
              <div>
                <p className="text-sm text-gray-600 mb-2">25 members going</p>
                <div className="flex -space-x-2">
                  {[...Array(4)].map((_, i) => (
                    <div key={i} className="w-6 h-6 rounded-full bg-gray-200 border-2 border-white"></div>
                  ))}
                  <div className="w-6 h-6 rounded-full bg-brand-teal text-white text-xs flex items-center justify-center border-2 border-white">
                    +21
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg">
            <div className="flex items-center justify-between mb-3">
              <h4 className="font-medium text-brand-navy">Best Travel Spots Poll</h4>
              <span className="px-2 py-1 bg-brand-teal/10 text-brand-teal rounded-md text-sm">48h left</span>
            </div>
            <div className="space-y-2">
              {["Bali, Indonesia", "Swiss Alps", "Costa Rica"].map((option, i) => (
                <div key={i} className="h-2 bg-gray-100 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-brand-teal"
                    style={{ width: `${[75, 60, 45][i]}%` }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  },
  {
    title: "Analytics & Insights",
    description: "Make data-driven decisions with detailed analytics",
    content: (
      <div className="bg-gray-50 h-full p-4 rounded-lg">
        <div className="mb-6">
          <h4 className="text-lg font-semibold text-brand-navy mb-4">Growth Metrics</h4>
          <div className="grid grid-cols-2 gap-4">
            {[
              { label: "Member Growth", value: "+24%", trend: "up" },
              { label: "Engagement Rate", value: "68%", trend: "up" },
              { label: "Revenue Growth", value: "+12%", trend: "up" },
              { label: "Active Members", value: "89%", trend: "stable" }
            ].map((metric, i) => (
              <div key={i} className="bg-white p-3 rounded-lg">
                <p className="text-sm text-gray-600 mb-1">{metric.label}</p>
                <div className="flex items-center space-x-2">
                  <span className="text-lg font-semibold text-brand-navy">{metric.value}</span>
                  <AiOutlineBarChart className="w-4 h-4 text-brand-green" />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <h5 className="font-medium text-brand-navy">Member Activity</h5>
            <div className="flex space-x-2">
              <div className="w-2 h-2 rounded-full bg-brand-teal"></div>
              <div className="w-2 h-2 rounded-full bg-gray-300"></div>
              <div className="w-2 h-2 rounded-full bg-gray-300"></div>
            </div>
          </div>
          <div className="h-24 flex items-end space-x-1">
            {[35, 45, 30, 60, 40, 50, 45, 35, 55, 40, 45, 50].map((height, i) => (
              <div key={i} className="flex-1">
                <div
                  className="bg-brand-teal/80 rounded-sm"
                  style={{ height: `${height}%` }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  },
  {
    title: "Monetization Hub",
    description: "Multiple revenue streams in one place",
    content: (
      <div className="bg-gray-50 h-full p-4 rounded-lg">
        <div className="space-y-4">
          <div className="bg-white p-4 rounded-lg">
            <h4 className="font-medium text-brand-navy mb-4">Revenue Streams</h4>
            <div className="space-y-3">
              {[
                { name: "Premium Memberships", amount: "$2,450", percentage: "75%" },
                { name: "Event Tickets", amount: "$1,280", percentage: "45%" },
                { name: "Partner Promotions", amount: "$890", percentage: "25%" }
              ].map((stream, i) => (
                <div key={i} className="flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-brand-navy">{stream.name}</p>
                    <p className="text-sm text-gray-600">{stream.amount}</p>
                  </div>
                  <div className="w-24 h-2 bg-gray-100 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-brand-teal"
                      style={{ width: stream.percentage }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <h4 className="font-medium text-brand-navy">Recent Transactions</h4>
              <button className="text-sm text-brand-teal">View All</button>
            </div>
            <div className="space-y-3">
              {[
                { name: "Sarah M.", type: "Premium Sub", amount: "+$49" },
                { name: "Travel Co.", type: "Partnership", amount: "+$299" },
                { name: "Mountain Trek", type: "Event", amount: "+$89" }
              ].map((tx, i) => (
                <div key={i} className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <div className="w-8 h-8 rounded-full bg-gray-200"></div>
                    <div>
                      <p className="text-sm font-medium text-brand-navy">{tx.name}</p>
                      <p className="text-xs text-gray-600">{tx.type}</p>
                    </div>
                  </div>
                  <span className="text-sm text-brand-green font-medium">{tx.amount}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
];