import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import CarouselButton from './CarouselButton';
import CarouselDots from './CarouselDots';
import FeatureMockup from './FeatureMockup';

export default function MockupCarousel({ mockups }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % mockups.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prev) => (prev - 1 + mockups.length) % mockups.length);
  };

  return (
    <div className="relative">
      <div className="overflow-hidden">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
            className="w-full"
          >
            <FeatureMockup {...mockups[currentIndex]} />
          </motion.div>
        </AnimatePresence>
      </div>

      <CarouselButton direction="left" onClick={handlePrevious} />
      <CarouselButton direction="right" onClick={handleNext} />

      <CarouselDots
        total={mockups.length}
        current={currentIndex}
        onSelect={setCurrentIndex}
      />
    </div>
  );
}