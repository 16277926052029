import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './screens/Home';
import Communities from './screens/Communities';
import TourGuides from './screens/TourGuides';
import ContentCreators from './screens/ContentCreators';
import Navbar from './components/Navbar';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/communities" element={<Communities />} />
        <Route path="/tour-guides" element={<TourGuides />} />
        <Route path="/content-creators" element={<ContentCreators />} />
      </Routes>
    </Router>
  );
}

export default App;
