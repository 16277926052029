import React from 'react';
import MockupFrame from './MockupFrame';

export default function FeatureMockup({ title, description, content }) {
  return (
    <div className="flex flex-col space-y-4">
      <div className="text-center">
        <h3 className="text-xl font-semibold text-brand-navy mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
      <MockupFrame className="w-full aspect-[4/3] overflow-hidden">
        {content}
      </MockupFrame>
    </div>
  );
}