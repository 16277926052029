export default {
    colorPalette: {
        // Primary color for main elements (headlines, important text, and backgrounds)
        primary: "#008B8B", // Teal - Represents energy, freshness, and global connection

        // Secondary color for call-to-action buttons, highlights, and important actions
        secondary: "#4CAF50", // Natural Green - Represents growth, trust, and nature

        // Accent color for decorative elements, icons, or small highlights
        accent: "#002B5B", // Deep Navy - Adds professionalism, stability, and contrast

        // Neutral colors for text and background contrasts
        neutral: {
            dark: "#333333", // Dark Gray - For main body text or dark accents
            light: "#F9FAFB", // Very Light Gray - For form backgrounds or secondary areas
        },

        // Background color for clean and readable layouts
        background: "#FFFFFF", // White - Clean and neutral base for the website

        // Optional gradients for buttons, headers, and highlights
        gradients: {
            primaryGradient: "linear-gradient(90deg, #20B2AA 0%, #43AA8B 100%)", // For buttons or major headers
            accentGradient: "linear-gradient(90deg, #002B5B 0%, #20B2AA 100%)", // For subtle decorative highlights
        },
    },
    button: {
        default: "w-full sm:w-auto border-2 border-white/50 text-white px-8 py-4 rounded-full text-lg font-semibold flex items-center justify-center gap-2 hover:bg-white/10 transition-all",
        // Button styles for primary actions
        primary:
            "bg-gradient-to-r from-[#43AA8B] to-[#20B2AA] text-white px-8 py-4 rounded-full text-lg font-semibold hover:shadow-xl transform hover:-translate-y-1 transition-all duration-200 border-2 border-white",

        secondary: "w-full sm:w-auto bg-brand-teal text-white px-8 py-4 rounded-full text-lg font-semibold flex items-center justify-center gap-2 hover:bg-brand-navy transition-all transform hover:scale-105 shadow-lg",

        // Button styles for secondary/transparent buttons
        transparent:
            "inline-flex items-center justify-center px-8 py-4 bg-white/10 backdrop-blur-sm text-white font-semibold rounded-full border-2 border-white/20 hover:bg-white/20 transition-all",

        // Larger button for call-to-actions
        bigger:
            "hover:bg-[#20B2AA]/90 transition-all transform hover:scale-105 shadow-lg hover:shadow-xl",
    },
};
