import React, { useState, useEffect } from 'react';
import { IoMenu, IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';

export default function Navbar() {
  const { t } = useTranslation();

  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav
      className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-white/90 backdrop-blur-md shadow-md' : 'bg-transparent'}`}
      style={{ height: "var(--navbar-height)" }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
        <div className="flex justify-between items-center w-full">
          {/* Logo Section */}
          <div className="flex items-center gap-2 sm:gap-4">
            <img
              src={logo}
              style={{ backgroundColor: isScrolled ? "transparent" : "white", borderRadius: "50%" }}
              alt="TripUnity Logo"
              className="w-16 h-auto sm:w-20"
            />
            <span
              className={`text-xl sm:text-3xl font-bold whitespace-nowrap ${isScrolled ? 'text-brand-navy' : 'text-white'}`}
            >
              TripUnity
            </span>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8 rtl:space-x-reverse">
            <Link to="/" className={`${isScrolled ? 'text-gray-700' : 'text-white'} hover:text-brand-teal transition-colors`}>
              {t("navigation.travelers")}
            </Link>
            <Link to="/communities" className={`${isScrolled ? 'text-gray-700' : 'text-white'} hover:text-brand-teal transition-colors`}>
              {t("navigation.communities")}
            </Link>
            <Link to="/tour-guides" className={`${isScrolled ? 'text-gray-700' : 'text-white'} hover:text-brand-teal transition-colors`}>
              {t("navigation.tour_guides")}
            </Link>
            <LanguageSelector />
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className={`${isScrolled ? 'text-gray-700' : 'text-white'}`}
            >
              {isMobileMenuOpen ? <IoClose size={24} /> : <IoMenu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-white shadow-lg">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link to="/" className="block px-3 py-2 text-gray-700 hover:text-brand-teal">
              {t("navigation.travelers")}
            </Link>
            <Link to="/communities" className="block px-3 py-2 text-gray-700 hover:text-brand-teal">
              {t("navigation.communities")}
            </Link>
            <Link to="/tour-guides" className="block px-3 py-2 text-gray-700 hover:text-brand-teal">
              {t("navigation.tour_guides")}
            </Link>
            <div className="px-3 py-2">
              <LanguageSelector />
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
