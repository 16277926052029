import React from 'react';
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

export default function CarouselButton({ direction, onClick }) {
  return (
    <button
      onClick={onClick}
      className="absolute top-1/2 -translate-y-1/2 bg-white/90 hover:bg-white text-brand-navy rounded-full p-2 shadow-lg z-10 transition-all duration-200"
      style={{ [direction === 'left' ? 'left' : 'right']: '1rem' }}
    >
      {direction === 'left' ? (
        <MdChevronLeft className="w-6 h-6" />
      ) : (
        <MdChevronRight className="w-6 h-6" />
      )}
    </button>
  );
}