import React from "react";
import styles from "../utility/styles";

export default function Button({ text = '', onClick, style, className, children }) {
  return (
    <button
      onClick={onClick}
      className={[styles.button.default, className].join(' ')}
      style={style}
    >
      {text}
      {children}
    </button>
  );
}
