import React from 'react';
import Feature from '../Feature';
import { FaGlobeAmericas } from "react-icons/fa";
import { FaRegCreditCard } from "react-icons/fa";
import { AiOutlineBarChart } from "react-icons/ai";
import { FaMapMarkedAlt } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

export default function Features() {
  const { t } = useTranslation();

  const features = [
    {
      icon: FaGlobeAmericas,
      title: t("tour_guides.features.connect_h"),
      description: t("tour_guides.features.connect_p")
    },
    {
      icon: FaRegCreditCard,
      title: t("tour_guides.features.tickets_h"),
      description: t("tour_guides.features.tickets_p")
    },
    {
      icon: FaMapMarkedAlt,
      title: t("tour_guides.features.digital_tours_h"),
      description: t("tour_guides.features.digital_tours_p")
    },
    {
      icon: AiOutlineBarChart,
      title: t("tour_guides.features.management_h"),
      description: t("tour_guides.features.management_p")
    }
  ];

  return (
    <div className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="p-2 text-4xl font-bold mb-6 bg-gradient-to-r from-brand-navy to-brand-teal bg-clip-text text-transparent">
            {t("tour_guides.why_us_h")}
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <Feature key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
}