import { useState, useEffect } from 'react'
import Hero from '../components/tourguides/Hero'
import scrollreveal from "scrollreveal";
import Header from '../components/Header'
import MockupCarousel from '../components/mockups/MockupCarousel'
import mockupData from '../components/mockups/mockupData'
import { useTranslation } from 'react-i18next';
import { analytics } from '../firebase';
import { logEvent } from "firebase/analytics";
import Features from '../components/tourguides/Features';
import CallToAction from '../components/CallToAction';
import WaitlistForm from '../components/tourguides/WaitlistForm';
import constants from '../utility/constants';

function TourGuides() {
    const [useWaitlistForm, setUseWaitlistForm] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const source = urlParams.get("source") || "direct";
        try {
            logEvent(analytics, "page_view", { page: "tourguides", source });
        } catch (error) {
            console.log("error", error)
        }
    }, []);

    useEffect(() => {
        const sr = scrollreveal({
            origin: "top",
            distance: "80px",
            duration: 2000,
            reset: true,
        });
        sr.reveal(
            `
            #hero_pros,
            #callToAction
            `,
            {
                opacity: 0,
                interval: 300,
            }
        );
    }, []);


    return (
        <div>
            <Hero
                actionBtnPress={() => setUseWaitlistForm(true)}
            />
            <Features />

            <CallToAction
                btnPress={() => setUseWaitlistForm(true)}
                title={t("tour_guides.cta_h")}
                subTitle={t("tour_guides.cta_p")}
                actionBtnText={t("join_waitlist")}
            />


            <WaitlistForm
                audience={constants.audience.tour_guides}
                visible={useWaitlistForm}
                dismiss={() => setUseWaitlistForm(false)}
            />
        </div>
    );
}
export default TourGuides;
