import { useState, useEffect } from 'react'
import { analytics } from '../firebase';
import { logEvent } from "firebase/analytics";

import React from 'react';
import Hero from '../components/Hero';
import Features from '../components/Features';
import JoinWaitlist from '../components/JoinWaitlist';
import CallToAction from '../components/CallToAction';
import { useTranslation } from 'react-i18next';
import TourGuideWaitlistForm from '../components/WaitlistForm';
import constants from '../utility/constants';

function Home() {
    const [useWaitlistForm, setUseWaitlistForm] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const source = urlParams.get("source") || "direct";
        logEvent(analytics, "page_view", { page: "Home", source });
    }, []);

    return (
        <div className="min-h-screen">
            <Hero
                actionBtnPress={() => setUseWaitlistForm(true)}
            />
            <Features />
            <CallToAction
                actionBtnText={t("join_waitlist")}
                title={t("waitlist.waitlist_cta.headline")}
                subTitle={t("waitlist.waitlist_cta.description")}
                btnPress={() => setUseWaitlistForm(true)}
            />

            <TourGuideWaitlistForm
                audience={constants.audience.travelers}
                visible={useWaitlistForm}
                dismiss={() => setUseWaitlistForm(false)}

            />
        </div>
    );
}

export default Home;