import React from 'react';

export default function MockupFrame({ children, className = '' }) {
  return (
    <div className={`relative rounded-2xl shadow-2xl bg-white ${className}`}>
      <div className="absolute top-0 left-0 right-0 h-6 bg-gray-100 rounded-t-2xl flex items-center px-2">
        <div className="flex space-x-1.5">
          <div className="w-2.5 h-2.5 rounded-full bg-red-500"></div>
          <div className="w-2.5 h-2.5 rounded-full bg-yellow-500"></div>
          <div className="w-2.5 h-2.5 rounded-full bg-green-500"></div>
        </div>
      </div>
      <div className="pt-8 p-4">
        {children}
      </div>
    </div>
  );
}