import React, { useState } from "react";
import { logEvent } from "firebase/analytics";
import { collection, addDoc } from "firebase/firestore";
import { db, analytics } from "../firebase";
import { useTranslation } from 'react-i18next';
import constants from "../utility/constants";

const TourGuideWaitlistForm = ({ visible, dismiss, audience }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await addDoc(collection(db, constants.db.waitlist), {
        ...formData,
        audience,
        date: new Date(),
      });
      logEvent(analytics, constants.events.travelersWaitlist, { audience, ...formData });
      alert(t("thankYouMessage"));
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
      });
      dismiss();
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("An error occurred. Please try again.");
    }
  };

  if (!visible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6 relative">
        <button
          onClick={dismiss}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 text-2xl"
        >
          &times;
        </button>

        <h2 className="text-2xl font-bold text-center mb-4 text-gray-700">
          {t("waitlist.title")}
        </h2>
        <p className="text-sm text-center text-gray-500 mb-6">
          {t("waitlist.subtitle")}
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex">
            <input
              type="text"
              name="firstName"
              placeholder={t("waitlist.firstNamePlaceholder")}
              value={formData.firstName}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-teal-500 focus:outline-none ms-0.5"
            />

            <input
              type="text"
              name="lastName"
              placeholder={t("waitlist.lastNamePlaceholder")}
              value={formData.lastName}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-teal-500 focus:outline-none ms-0.5"
            />
          </div>

          <input
            type="email"
            name="email"
            placeholder={t("waitlist.emailPlaceholder")}
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-teal-500 focus:outline-none"
          />

          <button
            type="submit"
            className="w-full bg-gradient-to-r from-green-400 to-teal-500 text-white py-2 rounded-lg font-semibold hover:shadow-lg transform hover:scale-105 transition-all duration-200"
          >
            {t("waitlist.submitButton")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default TourGuideWaitlistForm;
