import React from 'react';
import { IoGlobeOutline } from "react-icons/io5";
import { LuUsers } from "react-icons/lu";
import { FiMapPin } from "react-icons/fi";
import travelersSelfie from '../assets/travelers-selfie.jpeg';
import Button from './Button';
import styles from '../utility/styles';
import { useTranslation } from 'react-i18next';

export default function Hero({ actionBtnPress }) {
  const { t } = useTranslation();

  const headline = t("hero_h").split(",")

  return (
    <div className="relative min-h-screen overflow-hidden bg-gradient-to-br from-brand-navy via-brand-teal to-brand-green">
      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-96 h-96 -top-12 -right-12 bg-brand-teal/20 rounded-full blur-3xl animate-pulse"></div>
        <div className="absolute w-96 h-96 bottom-0 left-0 bg-brand-green/20 rounded-full blur-3xl animate-pulse delay-700"></div>
      </div>

      {/* Content */}
      <div className="relative z-10 max-w-7xl mx-auto px-4 pt-32 pb-16 flex flex-col lg:flex-row items-center justify-between min-h-screen">
        <div className="lg:w-1/2 text-white mb-12 lg:mb-0">
          <h1 className="text-5xl lg:text-7xl font-bold mb-6 leading-tight animate-fade-in">
            {headline[0]}<br />
            {headline[1]}
          </h1>
          <p className="text-xl lg:text-2xl mb-8 max-w-xl mx-auto lg:mx-0">
            {t("hero_p")}
          </p>

          <Button
            text={t("join_waitlist")}
            className={styles.button.bigger + " " + styles.button.primary}
            onClick={actionBtnPress}
          />
        </div>

        {/* Hero Image */}
        <div className="lg:w-1/2 relative">
          <div className="relative w-full max-w-lg mx-auto">
            <div className="absolute top-0 -left-4 w-72 h-72 bg-brand-green/30 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
            <div className="absolute top-0 -right-4 w-72 h-72 bg-brand-teal/30 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
            <div className="absolute -bottom-8 left-20 w-72 h-72 bg-brand-navy/30 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
            <img
              src={travelersSelfie}
              alt="Travel Adventure"
              className="relative rounded-2xl shadow-2xl transform hover:scale-105 transition-transform duration-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
}