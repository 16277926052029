import React from 'react';

export default function CarouselDots({ total, current, onSelect }) {
  return (
    <div className="flex justify-center space-x-2 mt-6">
      {Array.from({ length: total }).map((_, index) => (
        <button
          key={index}
          onClick={() => onSelect(index)}
          className={`w-2.5 h-2.5 rounded-full transition-all duration-200 ${current === index
            ? 'bg-brand-teal scale-125'
            : 'bg-gray-300 hover:bg-gray-400'
            }`}
        />
      ))}
    </div>
  );
}