// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import en from './locales/en/translation.json';
import he from './locales/he/translation.json';
import it from './locales/it/translation.json';
// Initialize i18n
i18n
    .use(LanguageDetector) // Detects user's language
    .use(initReactI18next)  // Passes i18n to React
    .init({
        resources: {
            en: { translation: en },
            he: { translation: he },
            it: { translation: it },
        },
        fallbackLng: 'en', // Default language if detection fails
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage', 'cookie'],
        },
        interpolation: {
            escapeValue: false, // React already escapes values
        },
    });

export default i18n;
